import React, { useContext } from 'react'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import './Base.sass'
import Button from '../../ui/Button/Button'
import { AuthContext } from '../../App'
import Icon from '../../ui/Icon/Icon'
import bgimage from './bgimage.jpg'
import Carousel from '../../ui/old/Carousel/Carousel'
import i1 from './photos/1.jpg'
import i2 from './photos/2.jpg'
import i3 from './photos/3.jpg'
import i4 from './photos/4.jpg'
import i5 from './photos/5.jpg'
import i6 from './photos/6.jpg'
import i7 from './photos/7.jpg'
import i8 from './photos/8.jpg'
import i9 from './photos/9.jpg'

function Base() {
  const benefits = [
    {
      title: 'Здоровье для души и тела',
      description: `Высокий сосновый лес, чистый воздух, баня, живописная природа`,
      icon: 'map',
    },
    {
      title: 'Близость к горнолыжным склонам:',
      description: `«Салма» - 50 км, «Лопарьстан» - 46 км, «Большой Вудъявр» - 43 км`,
      icon: 'skiing',
    },
    {
      title: 'Расположена на берегу озера Кислое',
      description: `купание, рыбалка, прорубь`,
      icon: 'water',
    },
    {
      title: 'Большой банкетный зал',
      description: `Вместимостью до 30 человек с возможностью размещения на ночь.
Для организации свадеб, банкетов, корпоративных мероприятий, тренингов.`,
      icon: 'glass-cheers',
    },
  ]

  const photos = [i1, i2, i3, i4, i5, i6, i7, i8, i9]

  return (
    <section
      id="base"
      name="base"
      class="Base"
      style={{ backgroundImage: `url(${bgimage})` }}
    >
      <div className="DefaultContainer">
        <SectionTitle title="Предложение для Вашего здоровья от наших партнеров" />
        <div class="Base-Container">
          <div class="Base-Text">
            <p>
              <b>База отдыха «КУСКАС»</b> расположена в Апатитском районе
              Мурманской области на берегу озера Кислое. Именно этот район
              Кольского полуострова знаменит своими горнолыжными склонами,
              прекрасным Северным сиянием. Идеальное место как для спокойного
              отдыха в атмосфере комфорта и уюта, так и для активного отдыха.
              <br />
              <br />
              <b>«КУСКАС»</b> окружает высокий сосновый лес, где можно
              прогуляться по дорожкам, подышать кристально чистым сосновым
              воздухом и покормить белок. Работаем круглый год, без перерывов и
              выходных, чтобы дарить Вам яркие впечатления!
              <br />
              <br />
              <b>База отдыха «КУСКАС»</b> — понравится каждому!
            </p>
            <div className="Benefits-Container">
              {benefits.map((b) => (
                <div className="BenefitTile">
                  <Icon name={b.icon} weight="solid" />
                  <div className="Text">
                    <p className="Title">{b.title}</p>
                    <p className="Description">{b.description}</p>
                  </div>
                </div>
              ))}
            </div>

            <Button
              theme="primary-white"
              title="Перейти на сайт партнеров"
              type="link"
              icon="arrow-right"
              iconPosition="right"
              path="https://kuskas.ru/"
              useTarget
            />
          </div>
          <div className="BaseImg">
            <Carousel
              options={{
                type: 'carousel',
                autoplay: 5000,
                useDots: true,
                useArrows: true,
              }}
            >
              {photos.map((p) => (
                <img src={p} key={p} alt="" />
              ))}
            </Carousel>
            {/* <img src={bgImage} alt="Gusto" /> */}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Base
